import React from 'react';
import Barcode from 'react-barcode';

export default function BarcodeComponent({ value, height, displayValue = true, color = 'white' }) {

    height = typeof (height) == 'undefined' ? 100 : height;

    return value === '' ?

        <div /> :

        <div className='barcode-holder' style={{ backgroundColor: color, padding: '3px' }}>
            <Barcode
                background={color}
                value={value} displayValue={displayValue} height={height} />
        </div>
}
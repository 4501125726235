import * as ActionTypes from "../actions/actionTypes";

function accountReducer(state = {}, action) {
    switch (action.type) {

        case ActionTypes.IS_LOGGED_IN: {
            return {
                ...state,
                [action.key]: action.payload
            };
        }

        case ActionTypes.IS_SUPPORTED_PWA: {
            return {
                ...state,
                [action.key]: action.payload
            };
        }

        case ActionTypes.PWA_INSTALLER: {
            return {
                ...state,
                [action.key]: action.payload
            };
        }

        default:
            return state
    }
}

export default accountReducer
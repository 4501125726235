import React, { Component } from 'react';
import "./Home.css"
import TopHeaderCell from './cells/top_header_cell/TopHeaderCell';
import GenericRowCell from './cells/generic_row_cell/GenericRowCell';
import SectionTitleCell from './cells/section_title_cell/SectionTitleCell'
import SectionTextCell from './cells/section_text_cell/sectionTextCell';
import SectionTextTitleCell from './cells/section_text_cell/sectionTextTitleCell';
import PointShopItemCell from './cells/point_shop_item_cell/PointShopItemCell';
import AlsoYoursCell from './cells/also_yours_cell/AlsoYoursCell';
import PerkCell from './cells/perk_cell/PerkCell';
import birthday_stroke from '../../images/birthday_stroke.png';
import birthday_bg from '../../images/birthday_bg.png';
import reviewBg from '../../images/review_bg.png';
import reviewStroke from '../../images/review_stroke.png';
import rewardFriendStroke from '../../images/reward_friend_stroke.png';
import rewardFriendBg from '../../images/reward_friend_bg.png';
import useSubscriptionBg from '../../images/use_subscription_bg.png';
import useSubscriptionStroke from '../../images/use_subscription_stroke.png';
import { timeValueFromDate, inviteFriends, getIconNameByType, isLayout122, getInjectedBizData, hasTopValueBar, checkIfNullUndefinedEmpty, formattedNumber, isPWASupportedOnCurrentBrowser } from '../../shared/static_helper/StaticHelper'
import { getCustomer, getBiz, getLanguage, getRedirectData } from '../../shared/redux/GlueStoreData'
import apiClient from '../../api/apiClient'
import Skeleton from '@mui/material/Skeleton'
import { Box, Grid } from '@mui/material';
import GenericButtonCell from './cells/generic_button_cell/GenericButtonCell';
import TopHeaderCellV2 from './cells/top_header_cell/v2/TopHeaderCellV2';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import PersonalDataScreen from '../personal_data/PersonalDataScreen';
import HorizontalScroller from '../../components/horizontal_scroller/HorizontalScroller';
import EditProfileScreen from '../edit_profile/EditProfileScreen';
import { buyWithPoints } from "../../shared/loyalty_helper/LoyaltyHelper";
import { store } from '../../shared/redux/store'
import { P2Styled, Toast, getBizColorNumber } from '../../biz_styles/bizStyle'
import { SpacerStyled } from '../../biz_styles/ReusableStyles';
import textFit from 'textfit';
import PointsConversionDrawer from '../points_conversion/PointsConversionDrawer';

class Home extends Component {

  state = {

    isDrawerOpen: false,
    drawerType: "benefits",
    biz: getBiz(),
    cust: getCustomer(),
    isLoading: false,
    hasPointsShop: false,
    getLang: getLanguage(),
    redirectData: getRedirectData(),

    //Dummy data for now
    getStartedData: [
      { id: 1, title: "Loading...", subtitle: "...", img1: "", img2: "" },
      { id: 2, title: "Loading...", subtitle: "...", img1: "", img2: "" }
    ],
    earnMorePointsData: [
      { id: 3, title: "Write a review", subtitle: "10 points", img1: reviewBg, img2: reviewStroke },
      { id: 5, title: "Refer a friend", subtitle: "50 points", img1: rewardFriendBg, img2: rewardFriendStroke }
    ],
    buyWithPointsData: [
      { id: 6, title: "", points: "", image: "" },
      { id: 7, title: "", points: "", image: "" },
      { id: 8, title: "", points: "", image: "" }
    ],
    perksData: [
      { id: 9, title: "Perk 1", subtitle: "Perk benefits", image: "https://smaller-pictures.appspot.com/images/dreamstime_xxl_65780868_small.jpg" },
      { id: 10, title: "Perk 2", points: "Perk benefits", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1YtRtoIL3ZWyhBtsJBBuI_M-llJRqX-R7pa1QZ50L3tAEZ-GHZuYTiHBf4iP5M4kyPrM&usqp=CAU" },
    ],

    gridData: [
      { id: 11, title: "whats_yours_rewards", image: 'rewards.svg', type: "benefits" },
      { id: 12, title: "whats_yours_my_loyalty", image: 'my_loyalty.svg', type: "loyalty_cards" },
      { id: 13, title: "whats_yours_my_subscriptions", image: 'my_subscriptions.svg', type: "subscriptions" },
      { id: 14, title: "whats_yours_my_punchpasses", image: 'my_punchpasses.svg', type: "punchpass" }
    ],

    loyaltyLayoutSectionsArray: [],
    elementsArray: []
  }

  getWelcomeString() {

    const currentDate = new Date()
    let currentTimeValue = timeValueFromDate(currentDate);

    let welcome = ''

    if (currentTimeValue <= 500) {
      // welcome = localizedTextForCode(@"2306"); //lovely dawn
      welcome = this.state.getLang.good_dawn
    }
    else if (currentTimeValue > 500 && currentTimeValue <= 1200) {
      // welcome = localizedTextForCode(@"2302"); //good morning
      welcome = this.state.getLang.good_morning
    }
    else if (currentTimeValue > 1200 && currentTimeValue <= 1700) {
      // welcome = localizedTextForCode(@"2303"); //Good afternoon
      welcome = this.state.getLang.good_afternoon
    }
    else if (currentTimeValue > 1700 && currentTimeValue <= 2100) {
      // welcome = localizedTextForCode(@"2304"); //Good evening
      welcome = this.state.getLang.good_evening
    }
    else if (currentTimeValue > 2100 && currentTimeValue <= 2359) {
      // welcome = localizedTextForCode(@"2305"); //Good night
      welcome = this.state.getLang.good_night
    }

    return welcome
  }


  componentDidMount() {

    this.getMainScreenDisplay();

    this.getWelcomeString();

    store.subscribe(() => {
      this.setState({ cust: getCustomer() })
    })

    if (!checkIfNullUndefinedEmpty(this.state.redirectData)) {
      this.openDrawerType('benefits');
    }

    textFit(document.getElementsByClassName('points-value-label'), { multiLine: false })

  }

  dataIsready() {
    return this.state.loyaltyLayoutSectionsArray.length > 0
  }

  getEventImageForStroke(event_image, stroke) {

    switch (event_image) {
      case 'update_profile':
        return stroke ? birthday_stroke : birthday_bg;
      case 'reward_friend':
        return stroke ? rewardFriendStroke : rewardFriendBg;
      case 'use_subscription':
        return stroke ? useSubscriptionStroke : useSubscriptionBg;
      default:
    }
    return null
  }

  topHeaderSection = () => {

    return <>

      {!this.dataIsready() ?
        <React.Fragment>
          <Skeleton animation="wave" variant="rectangular" width='auto' height={this.isLayout122() ? 278 : 208} style={{ backgroundColor: getBizColorNumber(4), margin: 30, borderRadius: 10 }} />
        </React.Fragment>
        :
        (this.isLayout122() ?
          <TopHeaderCell greetText={this.getWelcomeString()} points={this.state.cust.cust_current_points} /> :
          <TopHeaderCellV2 greetText={this.getWelcomeString()} points={this.state.cust.cust_current_points} membership={this.state.cust.membership.name} />)
      }

      <div className="spacer"></div>

    </>
  }

  whatsYoursSection = () => {

    return <>

      <SectionTitleCell title={this.state.getLang.whats_yours} />

      <Grid container className='grid-container'>

        {this.state.gridData.map(cell => <GenericButtonCell action={() => this.openDrawerType(cell.type)} key={cell.id} title={this.state.getLang[cell.title]} image={cell.image} onClick={this.showRewards} />)}

      </Grid>

      <div className="spacer"></div>

    </>
  }

  whatElseIsYours = (index) => {

    return <>

      <SectionTitleCell title={this.state.getLang.also_yours} />

      {this.dataIsready() ? this.state.loyaltyLayoutSectionsArray[index].elements.map(
        cell =>

          <div onClick={() => this.openDrawerType(cell.pz_type)}>
            <AlsoYoursCell
              key={cell.pz_type}
              pzType={cell.pz_type}
              title={cell.title}
              img={getIconNameByType(cell.pz_type)} />
          </div>


      ) : ''}

      <div className="spacer"></div>
      <div className="spacer"></div>
    </>
  }

  getStartedSection = (index) => {

    return <>

      <SectionTitleCell title={this.state.getLang.get_started} />

      {!this.dataIsready() ?
        <React.Fragment>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton animation="wave" variant="rectangular" width={90} height={90} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 30, marginTop: 10 }} />
            <Skeleton animation="wave" variant="rectangular" width={150} height={20} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginTop: -10 }} />
          </Box>
          <Skeleton animation="wave" variant="rectangular" width={70} height={20} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 140, marginTop: -35 }} />

          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
            <Skeleton animation="wave" variant="rectangular" width={90} height={90} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 30, marginTop: 10 }} />
            <Skeleton animation="wave" variant="rectangular" width={150} height={20} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginTop: -10 }} />
          </Box>
          <Skeleton animation="wave" variant="rectangular" width={70} height={20} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 140, marginTop: -35 }} />

          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
            <Skeleton animation="wave" variant="rectangular" width={90} height={90} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 30, marginTop: 10 }} />
            <Skeleton animation="wave" variant="rectangular" width={150} height={20} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginTop: -10 }} />
          </Box>
          <Skeleton animation="wave" variant="rectangular" width={70} height={20} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 140, marginTop: -35 }} />

        </React.Fragment>
        :
        this.state.loyaltyLayoutSectionsArray[index].elements.map(cell => <GenericRowCell key={cell.id} title={cell.title} subtitle={cell.sub_title} img1={cell.event_image + '.svg'} onClickEvent={() => this.onClickEventCta(cell)} />)}

      <div className="spacer"></div>
    </>
  }

  handleGoToPointsShopClick = () => {
    this.props.navigate('/main/pages/pointsShop');
  }

  buyWithPointsSection = (index) => {

    return (
      <>
        <SectionTitleCell onActionClicked={() => this.handleGoToPointsShopClick()} title={this.state.getLang.buy_with_points} actionLabel={this.state.getLang.go_to_points_shop} />

        <HorizontalScroller
          cellWidth={310}
          scrollerHeight={130}
          sidePadding={10}
          dataIsready={this.dataIsready()}
          data={this.dataIsready() ? (this.state.loyaltyLayoutSectionsArray[index].elements) : null}
          cellClass={obj => <PointShopItemCell key={obj.itemId} width={'310'} title={obj.title} points={obj.points} image={obj.image} onClick={() => this.onPointsShopItemClick(obj)} />}
        />

        <div className="spacer"></div>
      </>
    )
  }

  onPointsShopItemClick = (obj) => {

    buyWithPoints(obj.itemId, obj.points, obj.title, this.state.cust)

  }

  onClickEventCta = (cell) => {

    switch (cell.action) {

      case 'personal_zone_rewards':
        this.openDrawerType('benefits');
        break;

      case 'update_profile':
        this.openDrawerType('edit_profile');
        break;

      case 'coupons':
        this.props.navigate('/main/pages/coupons');
        break;

      case 'referral_program':
        inviteFriends();
        break;

      case 'my_subscriptions':
        this.openDrawerType('subscriptions');
        break;

      case 'my_punchpasses':
        this.openDrawerType('punchpass');
        break;

      case 'my_orders':
        this.openDrawerType('orders');
        break;

      case 'subscriptions':
        this.props.navigate('/main/pages/subscriptions');
        break;

      case 'punchpasses':
        this.props.navigate('/main/pages/muiltipass');
        break;

      case 'custom_survey':
        this.props.navigate(`/main/pages/customForms/${cell.extra_data}`);
        break;

      case 'go_to_module':

        break;

      case 'points_conversion':
        if(Number(this.state.cust.cust_current_points) >= Number(this.state.biz.minimum_for_conversion)) {
          this.openDrawerType('points_conversion');
        } else {
          Toast.fire({
            title: this.state.getLang.sorry,
            text: this.state.getLang.you_dont_have_enough_points_to_claim_this_benefit,
            icon: 'error',
            confirmButtonText: this.state.getLang.ok_got_it
          })
        }
        
        break;

      case 'redeemRewards':
        this.openDrawerType('benefits');
        break;

      case 'points_shop':
        this.props.navigate('/main/pages/pointsShop');
        break;

      default:

        break;
    }
  }

  earnMorePointsSection = (index) => {

    return <>

      <SectionTitleCell title={this.state.getLang.earn_more_points} />
      {this.dataIsready() ? this.state.loyaltyLayoutSectionsArray[index].elements.map(
        cell => <GenericRowCell key={cell.event_id}
          title={cell.title}
          subtitle={formattedNumber(cell.points_amount) + ' ' + this.state.getLang.points}
          img1={cell.event_image + '.svg'}
          onClickEvent={() => this.onClickEventCta(cell)} />
      ) : ''}

      <div className="spacer"></div>
    </>
  }

  nextMembershipSection = (index) => {

    return <>

      <SectionTitleCell title={this.state.getLang.go_for_membership.replaceAll('#name#', this.state.loyaltyLayoutSectionsArray[index].membership_name)} />
      <SectionTextTitleCell title={this.state.getLang.membership_text.replaceAll('#points#', formattedNumber(this.state.loyaltyLayoutSectionsArray[index].points_needed)).replaceAll('#name#', this.state.loyaltyLayoutSectionsArray[index].membership_name)} />
      {this.state.loyaltyLayoutSectionsArray[index].elements.map(cell => <PerkCell key={cell.type} title={cell.title} subtitle={cell.sub_title} image={""} type={cell.type} />)}

      <div className="spacer"></div>
    </>
  }

  async getMainScreenDisplay() {

    try {
      const res = await apiClient.post("/getMainScreenDisplay");

      if (res.status === 200) {
        if (res.data.responseCode === 1) {
          this.setState(this.state.loyaltyLayoutSectionsArray = res.data.responseData)
          // for (let i = 0; i < res.data.responseData.length; i++) {
          //   if (res.data.responseData[i].name === "pointshop_popular") {
          //     this.setState(this.state.hasPointsShop = true)

          //   }
          // }

        }
      }

    } catch (err) {
      // console.log("getMainScreenDisplay error = " + err);
    }
  }

  getSectionForName() {

    this.state.elementsArray = []

    for (let i = 0; i < this.state.loyaltyLayoutSectionsArray.length; i++) {

      switch (this.state.loyaltyLayoutSectionsArray[i].name) {

        case 'whats_yours':
          this.state.elementsArray.push(this.whatsYoursSection())
          break

        case 'gain_points':
          this.state.elementsArray.push(this.earnMorePointsSection(i))
          break

        case 'what_else':
          this.state.elementsArray.push(this.whatElseIsYours(i))
          break

        case 'get_started':
          this.state.elementsArray.push(this.getStartedSection(i))
          break

        case 'pointshop_popular':
          this.state.elementsArray.push(this.buyWithPointsSection(i))
          break

        case 'next_membership':
          this.state.elementsArray.push(this.nextMembershipSection(i))
          break

      }

    }

    return this.state.elementsArray

  }

  isLayout122 = () => {

    return isLayout122();
  }

  getCurrentDrawerChild() {

    switch (this.state.drawerType) {
      case "benefits":
        return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'rewards'} label={this.state.getLang.redeem_now + '!'} api='benefits' rootElement='' noDataText={this.state.getLang.no_rewards_yet} />
      case "loyalty_cards":
        return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'my_loyalty'} label={this.state.getLang.my_loyalty} api='loyalty_cards' rootElement='rows' noDataText={this.state.getLang.no_stamps_on_your_cards_yet} />
      case "subscriptions":
        return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'my_subscriptions'} label={this.state.getLang.subscriptions} api='subscriptions' rootElement='' noDataText={this.state.getLang.no_subscriptions_yet} />
      case "punchpass":
        return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'my_punchpasses'} label={this.state.getLang.multipasses} api='punchpasses' rootElement='' noDataText={this.state.getLang.no_multipasses_yet} />
      case "orders":
        return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'Notepad'} label={this.state.getLang.orders} api='orders' rootElement='' noDataText={this.state.getLang.no_orders_yet} />
      case "edit_profile":
        return <EditProfileScreen isDialog={true} dialogClosed={this.closeDrawer} />
      case "points_conversion":
        return <PointsConversionDrawer drawerClosed={this.closeDrawer} /> 
      default:
    }
  }

  closeDrawer = () => {
    this.setState({ isDrawerOpen: false });
  }

  openDrawerType = (type) => {
    this.setState({ drawerType: type, isDrawerOpen: true });
  }

  pageHeightDelta = () => {
    return 7
  }

  render() {

    return (

      <>

        <DrawerComponent
          children={this.getCurrentDrawerChild()}
          isDrawerOpen={this.state.isDrawerOpen}
          closeDrawer={this.closeDrawer}>
        </DrawerComponent>

        {/* Scroll View content  */}
        <div id='scroll-parent' className='home-main-scroll-view' style={{ paddingTop: hasTopValueBar() ? '88px' : '44px' }} onScroll={this.props.handleScroll}>

          <this.topHeaderSection />

          

          <div>
            {this.getSectionForName()}
          </div>
          <SpacerStyled height={'70'} />
        </div>

      </>

    );
  }
}

export default Home;

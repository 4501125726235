export const setIsLoggedInAction = (type, key, payload) => ({
    type,
    key,
    payload
})

export const setIsSupportedPwaAction = (type, key, payload) => ({
    type,
    key,
    payload
})

export const setPwaInstallPromptAction = (type, key, payload) => ({
    type,
    key,
    payload
})
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { getBizColorNumber, PStyled, DivHorizontalStyled, Toast, DivCenterHorizontalStyled } from '../../biz_styles/bizStyle'
import { ScrollableDivStyled, DivCenterStyled, CardDivStyled, BoldColorLabelStyled, PrimeTitleInputStyled, DivXYCenterStyled, SideMarginDivStyled, ColorLabelStyled, FixedBottomDivStyled, DivXCenterStyled, BigButtonStyled, SpacerStyled } from '../../biz_styles/ReusableStyles'
import { convertPointsToCashForCustomer, getClientAfterUpdate, getBiz as getBizFromApi} from '../../api/ApiRequests'
import { InfiniteScroller } from "../../components/Infinite_scroller/InfiniteScroller";
import { getFormatedDateSQL } from '../../shared/static_helper/StaticHelper';
import { DivCardStyled, LabelStyled } from '../../biz_styles/PointsConversionStyle';
import { getBiz, getLanguage } from '../../shared/redux/GlueStoreData';
import Skeleton from '@mui/material/Skeleton'
import SliderStyled from '../../components/slider_component/SliderStyled'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import LoadingComponent from '../../components/loading_components/LoadingComponent'


function PointsConversionCalculator({ pointsForConversion, calculatorData, drawerClosed }) {

    const bizObject = useStoreProvider().biz
    const getLang = getLanguage();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [sliderMinValue, setSliderMinValue] = useState(Number(bizObject.minimum_for_conversion));
    const [sliderMaxValue, setSliderMaxValue] = useState(Number(pointsForConversion.points_available));
    const [sliderValue, setSliderValue] = useState(Number(bizObject.minimum_for_conversion));

    const [currentPointsValue, setCurrentPointsValue] = useState(Number(pointsForConversion.points_available));
    const [currentCashValue, setCurrentCashValue] = useState(0);

    // Set initial data
    useEffect(() => {
        const bizObj = getBiz()
        handleSliderChange('', Number(bizObj.minimum_for_conversion))
      }, []);

    function onContinueClick() {

        if (currentCashValue == 0) {
            Toast.fire({
                title: getLang.sorry,
                text: getLang.choose_greater_value,
                icon: 'error',
                confirmButtonText: getLang.ok_got_it
            })
            return;
        }

        setIsLoading(true);

        (async () => {
            try {
                await convertPointsToCashForCustomer(currentCashValue);
            } catch (error) {
                console.error("Error converting points to cash:", error);
            } finally {
                getBizFromApi();
                await getClientAfterUpdate();
                setIsLoading(false);
                drawerClosed()
            }
        })();

    }

    const handleSliderChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setSliderValue(newValue);
            convertPointsToCash()

            if(sliderMinValue == sliderMaxValue) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        }
    };

    function convertPointsToCash() {

        let cashValue = sliderValue / Number(calculatorData.conversion_rate)

        setCurrentCashValue(cashValue.toFixed(2))

        editAvailablePointsValue(sliderValue)

    }

    function editCashValue(newValue) {

        setCurrentCashValue(Number(newValue).toFixed(2))

        setSliderValue(Number(newValue) * Number(calculatorData.conversion_rate))

        editAvailablePointsValue(Number(newValue) * Number(calculatorData.conversion_rate))
    }

    function editAvailablePointsValue(newValue) {

        setCurrentPointsValue(Number(pointsForConversion.points_available) - Number(newValue))
    }

    return (
        <DivCenterStyled style={{ height: '100%', backgroundColor: getBizColorNumber(1) }} >

            <ScrollableDivStyled id="scrollableDiv" height='90%' style={{ paddingBottom: '15%', backgroundColor: getBizColorNumber(1) }}>

                <CardDivStyled style={{ minHeight: '154px', alignContent: 'center' }} sidemargin={'1%'} color={getBizColorNumber(4)} strokecolor={getBizColorNumber(2)}>

                    <DivCenterHorizontalStyled style={{ display: 'inline-flex' }}>
                        {/* <PrimeTitleInputStyled type={'number'} value={currentCashValue} onChange={(e) => editCashValue(e.target.value)} fontSize={32} color={getBizColorNumber(2)} /> */}
                        <ColorLabelStyled fontSize={42} color={getBizColorNumber(2)} >{currentCashValue}</ColorLabelStyled>
                        <ColorLabelStyled color={getBizColorNumber(2)} fontSize={32}>{bizObject.ess_currency_symbol}</ColorLabelStyled>
                    </DivCenterHorizontalStyled>


                    <ColorLabelStyled style={{ color: getBizColorNumber(2), opacity: 0.5 }} fontSize={22}>{getLang.you_have_left + ' ' + currentPointsValue + ' ' + getLang.points}</ColorLabelStyled>

                </CardDivStyled>

                <SpacerStyled height={50} />

                <DivXCenterStyled>
                    <SliderStyled
                        onChange={handleSliderChange}
                        onChangeCommitted={handleSliderChange}
                        minvalue={sliderMinValue}
                        maxvalue={sliderMaxValue}
                        value={sliderValue}
                        disabled={isDisabled} />
                </DivXCenterStyled>


                <SpacerStyled height={50} />

                <DivXCenterStyled style={{display: 'inline', position: 'fixed', width: '100%', left: 0, bottom: '20px'}}>
                    <BigButtonStyled variant="contained" onClick={() => onContinueClick()}>{getLang.convert_and_load}</BigButtonStyled>
                </DivXCenterStyled>

            </ScrollableDivStyled>


            {isLoading ? <LoadingComponent isLoading={isLoading} /> : null}

        </DivCenterStyled>

    )
}

export default PointsConversionCalculator;
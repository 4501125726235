import * as React from "react";
import Tab from "@mui/material/Tab";
import { getBizColorNumber } from "../../biz_styles/bizStyle";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { getBiz, getLanguage } from "../../shared/redux/GlueStoreData";
import {ScrollableDivStyled} from '../../biz_styles/ReusableStyles'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import PointsConversionCalculator from "./PointsConversionCalculator";
import PointsConversionHistory from "./PointsConversionHistory";

function InnerTabs({ pointsData, calculatorData, drawerClosed }) {
  const getLang = getLanguage();
  const bizObject = useStoreProvider().biz

  const [value, setValue] = React.useState("calculator");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        sx={{
          "& .MuiTab-root": {
            backgroundColor: getBizColorNumber(4),
            color: getBizColorNumber(2) + 66,
          },
          "& .MuiTabs-indicator": { backgroundColor: getBizColorNumber(3) },
          "& .MuiTab-root.Mui-selected": { color: getBizColorNumber(2) },
        }}
      >
        <Tab value="calculator" label={getLang.calculator} />
        <Tab value="history" label={getLang.tab_history} />
      </TabList>

      <ScrollableDivStyled id="scrollableDiv" height='calc(100% - 250px)' >

        <TabPanel value="calculator">
          <PointsConversionCalculator pointsForConversion={pointsData.points_for_conversion} calculatorData={calculatorData} drawerClosed={drawerClosed} />
        </TabPanel>
        <TabPanel value="history">
          <PointsConversionHistory prevHistoryData={pointsData.conversion_history} totalCash={pointsData.total_cash}/>
        </TabPanel>

      </ScrollableDivStyled>
    </TabContext>
  );
}

export default InnerTabs;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from '../src/shared/redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import { isSupported } from "firebase/messaging";
import { isNotificationPermissionGranted, isPushNotificationSupported, isPwaInstalled } from './shared/static_helper/StaticHelper';



const root = ReactDOM.createRoot(document.getElementById('root'));
// document.body.classList.add("direction-rtl");
root.render(
  <HashRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </HashRouter >
);

// if (isPwaInstalled() && isNotificationPermissionGranted()) {
//   navigator.serviceWorker.register('../../scripts/biz/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     }).catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
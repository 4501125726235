import ReactCodeInput from "react-code-input"
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import LoginFormHeader from './LoginFormHeader'
import './LoginScreen.css';
import { GET_CUSTOMER_DATA, IS_LOGGED_IN } from "../../actions/actionTypes"
import { store } from '../../shared/redux/store'
import { getCustomerAction } from "../../actions/customerActions"
import { setIsLoggedInAction } from '../../actions/accountActions'
import { WelcomeBackground, LoginDivStyled } from '../../biz_styles/LoginStyle';
import { validateClubMemberByPhone, validatePhoneCode, validateClubMemberByEmail, resendPhoneCode } from "../../api/ApiRequests"
import { checkIfNullUndefined, getBizHashFromUrl } from "../../shared/static_helper/StaticHelper"
import ReCAPTCHA from "react-google-recaptcha"
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'
import { Toast, getBizColorNumber } from "../../biz_styles/bizStyle"

function LoginFormSmsCode() {

    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const codeInputRef = useRef(undefined)
    const navigate = useNavigate();
    const captchaRef = useRef()
    const useLocationParam = useLocation();
    const useLocationParamState = useLocationParam.state;
    const getLang = getLanguage();
    var phoneNumber = ""
    var email = ""
    var sendTo = ""

    if (!checkIfNullUndefined(useLocationParamState)) {
        if (!checkIfNullUndefined(useLocationParamState.phoneNumber)) {
            phoneNumber = useLocationParamState.phoneNumber;
            sendTo = useLocationParamState.phoneNumber;
        }
        if (!checkIfNullUndefined(useLocationParamState.email)) {
            email = useLocationParamState.email;
            sendTo = useLocationParamState.email;
        }
    }

    function clearCodeInput() {
        for (var i = 0; i < 6; i++) {
            codeInputRef.current.state.input[i] = ""
        }
    }

    useEffect(() => {
        // Check if browser supports the Web OTP API
        if ('OTPCredential' in window) {
          navigator.credentials
            .get({
              otp: { transport: ['sms'] }
            })
            .then((otpCredential) => {
              // Autofill the OTP input with the received code
              if (otpCredential && otpCredential.code) {
                codeInputRef.current.state.input = otpCredential.code.split('');
              }
            })
            .catch((err) => {
              console.error('Error fetching OTP:', err);
            });
        }
      }, []);


    const handlePhoneCode = (e) => {
        if (String(e).replace(/[A-Za-z]/g, "").length === 6) {
            setIsLoading(true)
            validatePhone(String(e));
        }
    };

    async function validatePhone(code) {
        const res = await validatePhoneCode(code)
        if (res !== undefined) {

            store.dispatch(getCustomerAction(GET_CUSTOMER_DATA, getBizHashFromUrl(), res.responseData))
            store.dispatch(setIsLoggedInAction(IS_LOGGED_IN, getBizHashFromUrl(), true))
            setIsLoading(false)

            if (res.responseMessage === 'waiting_list') {
                navigate("../WaitingList");
            } else {
                navigate("../main");
            }

        } else {
            setIsLoading(false)
            clearCodeInput()
        }
    }

    async function SendCodeAgain(phoneNumber) {
        setIsLoading(true)
        const token = await captchaRef.current.executeAsync();
        const res = await resendPhoneCode(phoneNumber, token)
        if (res === undefined) {
            setIsLoading(false)
            captchaRef.current.reset();
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
        setIsLoading(false)
        captchaRef.current.reset();
    }

    async function SendEmailAgain(email) {
        const token = await captchaRef.current.executeAsync();
        setIsLoading(true)
        const res = await validateClubMemberByEmail(email, token)
        if (res === undefined) {
            setIsLoading(false)
            captchaRef.current.reset();
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
        setIsLoading(false)
        captchaRef.current.reset();
    }


    function showError(title, text) {
        Toast.fire({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: getLang.ok,
            confirmButtonColor: getBizColorNumber(3),
        });
    }

    function partialPhoneNumber(candidate) {

        if (candidate.includes('@')) { //We got an Email
            return candidate;
        }
        else { //We got phone number
            const substring = candidate.substring(candidate.length - 3, candidate.length);
            return "+(xxx)xxx-xxx-" + substring;
        }
    }

    const props = {
        className: ReactCodeInput,
        inputStyle: {
            fontFamily: 'monospace',
            margin: '4px',
            width: '42px',
            borderRadius: '6px',
            fontSize: '25px',
            height: '42px',
            paddingLeft: '13px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid lightgrey'
        }
    }

    function redirectIfNeeded() {

        if (isLoggedIn()) {

            useEffect(() => {
                navigate('../main')
            }, []);
        }
    }

    return (
        <WelcomeBackground>
            {redirectIfNeeded()}
            <LoginDivStyled>
                <div className='parent-div'>
                    <LoadingComponent isLoading={isLoading} />

                    <LoginFormHeader
                        title={getLang.confirm_number}
                        subtitle={getLang.enter_code_sent} 
                        candidate={partialPhoneNumber(sendTo)}/>

                    <ReactCodeInput
                        ref={codeInputRef}
                        autoFocus={true}
                        name="one-time-code"
                        autoComplete="one-time-code"
                        inputMode="numeric"
                        fields={6}
                        type="text"
                        {...props}
                        onChange={(e) => handlePhoneCode(e)}
                    />

                    <div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={captchaRef}
                            size="invisible"
                        />
                        <p onClick={phoneNumber === "" ? () => SendEmailAgain(email) : () => SendCodeAgain(phoneNumber)} style={{ color: getBizColorNumber(3), cursor: 'pointer' }}>{getLang.didnt_get_an_sms_send_again}</p>
                    </div>
                </div>
            </LoginDivStyled>
        </WelcomeBackground>

    )

}

export default LoginFormSmsCode
import React, { useEffect, useState } from "react";
import RouterProvider from './RouterProvider';
import { Notifications } from 'react-push-notification';
import { AppStyled, Toast, getBizColorNumber } from "./biz_styles/bizStyle";
import './App.css'
import StoreContext from './shared/providers/StoreContext'

export const appMaxWidth = '550px'

function App() {

  // if (isPwaInstalled() && !isNotificationPermissionGranted()) {
  //   Toast.fire({
  //     allowOutsideClick: false,
  //     title: 'לאפשר התראות?',
  //     confirmButtonText: 'כן',
  //     showCancelButton: true,
  //     cancelButtonText: 'לא',
  //     reverseButtons: true,
  //     iconColor: getBizColorNumber(3),
  //     confirmButtonColor: getBizColorNumber(3),
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       requestFirebaseNotificationPermission()
  //     }
  //   })
  // }

  // requestFirebaseNotificationPermission()

  // if (isNotificationPermissionGranted()) {
  //   getTokenFromFirebase().then((res) => {
  //     if (res && res.length > 0) {
  //       registerFirebaseToken(res)
  //     }

  //   }).catch((error) => {
  //     console.error('error:', error);
  //   });
  // }

  // const [notificationState, setNotificationState] = useState({
  //   open: false,
  //   message: "",
  // });

  // const {
  //   open,
  //   message
  // } = notificationState;

  // const handleNotificationClick = () => {
  //   setNotificationState({
  //     ...notificationState,
  //     open: false
  //   });
  // };

  // const handleNotificationClose = () => {
  //   setNotificationState({
  //     ...notificationState,
  //     open: false
  //   });
  // };

  /**
  * Method that listens to firebase FCM notification.
  * Messages that are sent when the app is in use
  * trigger this method.
  */
  // onMessageListener()
  //   .then((payload) => {
  //     console.log("Received message: ", payload);
  //     setNotificationState({
  //       open: true,
  //       message: `🗓 ${payload.data.body}`,
  //     })
  //   }
  //   )
  //   .catch((err) => {
  //     console.log(`An error occured when showing notif ${err}`);
  //   });

  // const [supportsPWA, setSupportsPWA] = useState(false);
  // const [promptInstall, setPromptInstall] = useState(null);


  // if(isPWASupportedOnCurrentBrowser()) {

  //   useEffect(() => {
  //     const handler = e => {
  //       e.preventDefault();

  //       setSupportsPWA(true);
  //       setIsSupportedPWA(true)
  //       setPromptInstall(e);
  //       setPWAInstaller(e)
  //     };
  //     window.addEventListener("beforeinstallprompt", handler);

  //     return () => window.removeEventListener("transitionend", handler);
  //   }, []);

  //   if (!supportsPWA) {
  //     console.log('not supported')
  //     setIsSupportedPWA(false)
  //     setPWAInstaller(null)
  //     return null;
  //   }

  //   window.onappinstalled = () => {
  //     console.log("Thank you for installing our app!");
  //     setIsSupportedPWA(false)
  //     setPWAInstaller(null)
  //   };
  // }


  // Use it for update fav icon and tab name dynamically.
  // useEffect(() => {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   if (!checkIfNullUndefined(getBiz())) {
  //     document.title = getBiz().biz_short_name
  //     link.href = getBiz().biz_logo;
  //   }

  // }, []);

  return (
    <StoreContext>
      <AppStyled>
        <Notifications />
        <RouterProvider />
      </AppStyled>
    </StoreContext>
  );
}



export default App;

import apiClient from './apiClient'
import apiClientEasy from './apiClientEasy'
import { GET_CUSTOMER_DATA, CUSTOMER_REDIRECT_DATA, GET_LANGUAGE_DATA } from "../actions/actionTypes"
import { store } from '../shared/redux/store'
import { getCustomerAction } from "../actions/customerActions"
import { GET_BIZ_DATA, IS_LOGGED_IN } from '../actions/actionTypes' // eslint-disable-line
import { getBizAction } from "../actions/bizActions"
import { setIsLoggedInAction } from '../actions/accountActions'
import { getLanguageAction } from '../actions/languageActions'
import { checkIfBizColorsChanged, getBizHashFromUrl, getInjectedBizData } from '../shared/static_helper/StaticHelper'
import { Toast, getBizColorNumber } from '../biz_styles/bizStyle'

export async function getBizForSplashScreen() {
  try {

    const postData = {
      bizData: getInjectedBizData(),
    };

    const res = await apiClient.post("/initSplash", postData)
    if (res.status === 200) {
      if (res.data.responseCode === 1) {

        store.dispatch(getBizAction(GET_BIZ_DATA, getBizHashFromUrl(), getInjectedBizData()))
        store.dispatch(getCustomerAction(CUSTOMER_REDIRECT_DATA, CUSTOMER_REDIRECT_DATA, res.data.responseData.data))

        getBiz(res.data.responseMessage)

        return res.data;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {
    showErrorConnection();
  }
}

export async function getBiz(dest = '') {
  try {
    const res = await apiClient.post("/init/" + dest)
    if (res.status === 200) {
      if (res.data.responseCode === 1) {

        const receivedColorsList = [
          res.data.responseData.biz_theme_color1,
          res.data.responseData.biz_theme_color2,
          res.data.responseData.biz_theme_color3,
          res.data.responseData.biz_theme_color4,
          res.data.responseData.biz_theme_color5
        ]
        checkIfBizColorsChanged(receivedColorsList)

        store.dispatch(getBizAction(GET_BIZ_DATA, getBizHashFromUrl(), res.data.responseData))
        return res.data;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {
    showErrorConnection(err);
  }
}

export async function validateClubMemberByPhone(phoneNumber, captchaToken) {
  try {
    const postData = {
      phone: phoneNumber,
      identifier: "123",
      token: captchaToken
    };

    const res = await apiClient.post("/getLoginCode", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data
      } else {
        showErrorConnection();
      }
    } else {
      showErrorConnection();
    }

  } catch (err) {
    showErrorConnection();
  }
}

export async function validateClubMemberByEmail(email, captchaToken) {
  try {
    const postData = {
      email: email,
      token: captchaToken
    };

    const res = await apiClient.post("/getLoginCode", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data
      }
      else {
        showErrorConnection();
      }
    } else {
      showErrorConnection();
    }

  } catch (err) {
    showErrorConnection();

  }
}

export async function resendPhoneCode(phoneNumber, captchaToken) {
  try {
    const postData = {
      phone: phoneNumber,
      identifier: "123",
      token: captchaToken
    };

    const res = await apiClient.post("/resendCode", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data
      } else {
        showErrorConnection();
      }
    } else {
      showErrorConnection();
    }

  } catch (err) {
    showErrorConnection();
  }
}

export async function validatePhoneCode(code) {
  try {
    const postData = {
      code: code,
    };

    const res = await apiClient.post("/validateCode", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data
      } else {
        if (res.data.responseMessage === 'invalid_code') {
          showInvalidCodeError();
        } else if (res.data.responseMessage === 'no_server_customer_id') {
          showCacheError();
        } else {
          showErrorConnection();
        }
      }
    } else {
      showErrorConnection();
    }

  } catch (err) {
    showErrorConnection();
  }
}

export async function validatePhoneCodeForUserDataDeletion(code) {
  try {
    const postData = {
      code: code,
    };

    const res = await apiClient.post("/validateCodeForUserDataDeletion", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data
      } else {
        if (res.data.responseMessage === 'invalid_code') {
          showInvalidCodeError();
        } else {
          showErrorConnection();
        }
      }
    } else {
      showErrorConnection();
    }

  } catch (err) {
    showErrorConnection();
  }
}

export async function submitRegister(phoneNumber, captchaToken) {

  try {

    const postData = {
      phone: phoneNumber,
      identifier: "123",
      token: captchaToken
    };

    const res = await apiClient.post("/submitRegister", postData);

    if (res.status === 200) {

      if (res.data.responseCode === 1) {

        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function submitPhoneAfterVerification(phoneNumber, captchaToken) {
  try {
    const postData = {
      phone: phoneNumber,
      token: captchaToken
    };

    const res = await apiClient.post("/submitPhoneAfterVerification", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data
      }
      else {
        showErrorConnection()
      }
    } else {
      showErrorConnection()
    }

  } catch (err) {
    showErrorConnection()
  }
}

export async function getPersonalzone(urlPath, count) {
  try {
    const postData = {
      path: urlPath,
      count: count
    };
    const res = await apiClient.post("/getPersonalZone", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }

  } catch (err) {
    showErrorConnection();
  }
}

export async function zeroCustomerBadge(badge) {
  try {

    const postData = {
      badge: badge,
    };

    const res = await apiClient.post("/zeroCustomerBadge", postData);

    if (res.status === 200) {

      if (res.data.responseCode === 1) {

        return res.data.responseCode;
      } else {

        showErrorConnection();
      }
    }

  } catch (err) {

    showErrorConnection();
  }
}

export async function getMoreHistory(dataLength) {

  try {
    const postData = {
      count: dataLength,
    };

    const res = await apiClient.post("/getMorehistory", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }

  } catch (err) {
    showErrorConnection();
  }
}

export async function updateCustomer(data) {

  try {

    const res = await apiClient.post("/updateCustomer", data);

    if (res.status === 200) {

      if (res.data.responseCode === 1) {

        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getClientAfterUpdate() {

  try {
    const res = await apiClient.post("/getClientAfterUpdate");

    if (res.status === 200) {

      if (res.data.responseCode === 1) {
        store.dispatch(getCustomerAction(GET_CUSTOMER_DATA, getBizHashFromUrl(), res.data.responseData))
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getCustomerDirect() {

  try {
    const res = await apiClient.post("/getCustomer");

    if (res.status === 200) {

      if (res.data.responseCode === 1) {
        store.dispatch(getCustomerAction(GET_CUSTOMER_DATA, getBizHashFromUrl(), res.data.responseData))
        store.dispatch(setIsLoggedInAction(IS_LOGGED_IN, getBizHashFromUrl(), true))
        return res.data.responseCode;
      }
      else {
        return res.data.responseCode;
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getLevelData(params) {

  try {

    const res = await apiClient.post("/getLevelData", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function claimFreeCoupon(params) {

  try {

    const res = await apiClient.post("/claimFreeCoupon", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getPointsShop() {

  try {

    const res = await apiClient.post("/getPointsShop");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function claimFromPointShop(id) {

  try {
    const postData = {
      itemID: id,
    };

    const res = await apiClient.post("/claimFromPointShop", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getScratchCardImage(color) {
  try {
    const postData = {
      color: color,
    };

    const res = await apiClient.post("/getScratchImage", postData);

    if (res.status === 200) {
      return res.data;
    }

  } catch (err) {

    showErrorConnection();
  }
}

export async function claimScratchReward(cardId) {
  try {
    const postData = {
      cardId: cardId,
    };

    const res = await apiClient.post("/claimScratchReward", postData);

    if (res.status === 200) {
      return res.data.responseCode;
    }

  } catch (err) {

    showErrorConnection();
  }
}

export async function getCoordinatesFromString(country, state, city, street, no) {
  try {
    const postData = {
      country: country,
      state: state,
      city: city,
      street: street,
      no: no
    };

    const res = await apiClient.post("/getCoordinatesFromString", postData);

    if (res.status === 200) {
      return res.data.responseData;
    }

  } catch (err) {

    showErrorConnection();
  }
}

function showErrorConnection() {
  Toast.fire({
    title: 'Sorry!',
    text: 'Internet connection error',
    icon: 'error',
    confirmButtonColor: getBizColorNumber(3),
    confirmButtonText: 'Got It'
  })

}

function showInvalidCodeError() {
  Toast.fire({
    title: 'Sorry!',
    text: 'Incorrect code',
    icon: 'error',
    confirmButtonColor: getBizColorNumber(3),
    confirmButtonText: 'Got It'
  })

}

function showCacheError() {
  Toast.fire({
    title: 'Sorry!',
    text: 'Data corruption detected. Please refresh the page.',
    icon: 'error',
    confirmButtonColor: getBizColorNumber(3),
    confirmButtonText: 'Refresh'

  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = '/glue/biz/welcome/' + window.bizHash;
    }
  });

}


export async function addItemToCart(params) {

  try {

    const res = await apiClient.post("/addItemToCart", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function cancelSubscription(params) {

  try {

    const res = await apiClient.post("/cancelSubscription", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getIsRedeemed(params) {

  try {

    const res = await apiClient.post("/getIsRedeemed", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        if (res.data.responseMessage == "expired") {
          return "expired";
        }
        else {
          showErrorConnection();
        }

      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function canClaimCoupon(params) {

  try {

    const res = await apiClient.post("/canClaimCoupon", params);

    if (res.status === 200) {
      return res.data.responseMessage;
    }
    else {
      showErrorConnection();
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function setStripeOrder(params) {

  try {

    const res = await apiClient.post("/setStripeOrder", params);

    if (res.status === 200) {
      return res.data;

    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function setIsracardOrder(params) {

  try {

    const res = await apiClient.post("/setIsracard360Order", params);

    if (res.status === 200) {
      return res.data;

    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function setCustomerOrder(params) {

  try {

    const res = await apiClient.post("/setCustomerOrder", params);

    if (res.status === 200) {
      return res.data;

    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getCartDiscounts() {

  try {

    const res = await apiClient.post("/getCartDiscounts");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function addRewardToCart(params) {

  try {

    const res = await apiClient.post("/addRewardToCart", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getInviteLink() {

  try {

    const res = await apiClient.post("/getinviteLink", {});

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getLanguageCollection() {

  try {
    const language = getInjectedBizData().biz_language;
    const res = await apiClient.post("/getLanguageCollection/" + language, {});

    // const res = await apiClient.post("/getLanguageCollection/" + "en", {});

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        store.dispatch(getLanguageAction(GET_LANGUAGE_DATA, res.data));
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getFieldsList(params) {

  try {

    const res = await apiClient.post("/getFieldsList", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
    else {
      showErrorConnection();
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function saveCustomForm(params) {

  try {

    const res = await apiClient.post("/saveCustomForm", params);

    if (res.status === 200) {

      if (res.data.responseCode === 1) {

        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function deleteClient() {

  const params = {
    approved: 1,
  };

  try {

    const res = await apiClient.post("/deleteClient", params);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function signout() {

  try {

    const res = await apiClient.post("/signout");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function unsubscribe() {

  try {

    const res = await apiClient.post("/unsubscribe");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function deleteUserData() {

  try {

    const res = await apiClient.post("/deleteUserData");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function inviteSent() {

  try {

    const res = await apiClient.post("/inviteSent");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseCode;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function submitRating(params) {

  try {

    const res = await apiClient.post("/submitRating", params);

    if (res.status === 200) {

      if (res.data.responseCode === '1') {

        return res.data;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function addMemberFromRatingPagOnly(params) {
  try {

    const res = await apiClient.post("/addMemberFromRatingPagOnly", params);

    if (res.status === 200) {

      if (res.data.responseCode === '1') {

        return res.data;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export function directLoadBizData() {
  store.dispatch(getBizAction(GET_BIZ_DATA, getBizHashFromUrl(), getInjectedBizData()))
  return
}

export async function submitReview(params) {

  try {

    const res = await apiClient.post("/submitReview", params);

    if (res.status === 200) {

      if (res.data.responseCode === '1') {

        return res.data;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function addEasyReview(params) {

  try {

    const res = await apiClientEasy.post("/n/bizactions/addReview", params);

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) { }
}


// Points Conversion

export async function getConversionInitialDataForCustomer() {

  try {

    const res = await apiClient.post("/getConversionInitialDataForCustomer");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getPointsAmountAvailableForConversionForCustomer() {

  try {

    const res = await apiClient.post("/getPointsAmountAvailableForConversionForCustomer");

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function getCustomerConversionHistory(dataLength) {

  try {
    const postData = {
      count: dataLength,
    };
    const res = await apiClient.post("/getCustomerConversionHistory", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function convertPointsToCashForCustomer(amount) {

  const postData = {
    amount: amount,
  };

  try {

    const res = await apiClient.post("/convertPointsToCashForCustomer", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        showErrorConnection();
      }
    }
  } catch (err) {

    showErrorConnection();
  }
}

export async function registerFirebaseToken(token) {

  const postData = {
    token: token,
  };

  try {

    const res = await apiClient.post("/regiserPushToken", postData);

    if (res.status === 200) {
      if (res.data.responseCode === 1) {
        return res.data.responseData;
      }
      else {
        console.log('registerFirebaseToken err: ' + JSON.stringify(res))
      }
    }
  } catch (err) {
    console.log('registerFirebaseToken err: ' + JSON.stringify(err))
    showErrorConnection();
  }
}
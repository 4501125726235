import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import './SplashScreen.css'
import { getRedirectData, setIsLoggedIn, getLanguage, getCustomer } from '../../shared/redux/GlueStoreData'
import { getLanguageCollection, getCustomerDirect, getBizForSplashScreen } from '../../api/ApiRequests';
import { checkIfNullUndefined, checkIfNullUndefinedEmpty, getShowCustomerLoader, getInjectedBizData, isDark } from '../../shared/static_helper/StaticHelper';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import PersonalDataScreen from '../personal_data/PersonalDataScreen';
import { GlobalStyle, getBizColorNumber } from '../../biz_styles/bizStyle'
import { BizWebSocket } from '../../shared/web_socket/BizWebSocket';

const SplashScreen = () => {
  const navigate = useNavigate()

  const [isBizExists, setIsBizExists] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerChild, setDrawerChild] = useState(null);

  let isDarkBackground = getInjectedBizData().welcome_background_is_dark
  let loadingGifUrl = isDarkBackground ? 'https://storage.googleapis.com/getglue/images/web_member/easy_loader_light.gif' : 'https://storage.googleapis.com/getglue/images/web_member/easy_loader_dark.gif'

  useEffect(() => {
    getLanguageCollectionData();
  }, []);

  const splashClass = getShowCustomerLoader() == 1 ? "splash splash-show-loader" : "splash"

  return (

    <div className={splashClass}>

      <img src={loadingGifUrl} className='gif' alt="" />

      {
        isBizExists ?
          <div>
            <GlobalStyle />
            <DrawerComponent
              children={drawerChild}
              isDrawerOpen={isDrawerOpen}
              closeDrawer={closeDrawer}>
            </DrawerComponent>
          </div>
          : ""
      }

    </div>
  );

  async function getLanguageCollectionData() {

    const resData = await getLanguageCollection();

    if (!checkIfNullUndefined(resData)) {
      getbizData();
    }
  }

  async function getbizData() {
    try {

      const res = await getBizForSplashScreen()

      if (res.responseCode === 1) {

        setIsBizExists(true)

        const redirectData = getRedirectData()

        if (res.responseMessage === "welcome") {
          setIsLoggedIn(false);
          navigate("../welcome")
        }
        else if (res.responseMessage === "unsubscribe") {
          const custRes = await getCustomerDirect()
          if (custRes === 1) {
            navigate("../unsubscribe")
          }
        }
        else if (res.responseMessage === "home") {
          const custRes = await getCustomerDirect()
          if (custRes === 1) {
            const bizObject = getInjectedBizData();
            if (bizObject.club_active == 1) {
              setIsLoggedIn(true);
              setTimeout(() => {
                navigate("../main");
              }, 2000);
            } else {
              if (!checkIfNullUndefinedEmpty(redirectData)) {
                setTimeout(() => {
                  openDrawer()
                }, 2000);
              } else {
                navigate("../reactivateBiz");
              }
            }

          }
          else {
            setIsLoggedIn(false);
            navigate("../welcome")
          }
        }
        else if (res.responseMessage === "biz_disabled") {
          navigate("../reactivateBiz")
        }
        else if (res.responseMessage === "email_not_verified") {
          navigate("../LinkExpired")
        }
        else if (res.responseMessage === "email_verified") {
          navigate("../EmailVerified")
        }
        else if (res.responseMessage === "waiting_list") {
          navigate("../WaitingList")
        }
        else if (res.responseMessage === "registration") {
          const custRes = await getCustomerDirect()
          const bizObject = getInjectedBizData();
          if (custRes === 1) {
            setIsLoggedIn(false);
            setTimeout(() => {
              if (bizObject.email_member_enabled === "1") {
                navigate("../registerEmail")
              } else {
                navigate("../registerPhone")
              }
            }, 2000);
          }
        } else if (res.responseMessage === "registration_approved") {
          const custRes = await getCustomerDirect()
          const bizObject = getInjectedBizData();
          if (custRes === 1) {
            setIsLoggedIn(false);
            setTimeout(() => {
              if (bizObject.email_member_enabled === "1") {
                navigate("../registerEmail")
              } else {
                navigate("../registerPhoneApproved")
              }
            }, 2000);
          }
        } else if (res.responseMessage === "delete_account") {
          const custRes = await getCustomerDirect()
          const bizObject = getInjectedBizData();
          setIsLoggedIn(false);
          setTimeout(() => {
            if (bizObject.email_member_enabled === "1") {
              // navigate("../deleteUserDataByEmail")
              navigate("../deleteUserDataByPhone")
            } else {
              navigate("../deleteUserDataByPhone")
            }
          }, 2000);

        }

      }

    }
    catch (err) {
    }
  }

  function openDrawer() {
    setDrawerChild(getDrawerChild())
    setIsDrawerOpen(true)
  }

  function closeDrawer() {
    setIsDrawerOpen(false)
  }

  function getDrawerChild() {
    const getLang = getLanguage();
    return <PersonalDataScreen contentType={'benefits'} top_icon={'rewards'} label={getLang.redeem_now + '!'} api='benefits' rootElement='' noDataText={getLang.reward_is_being_prepared} />
  }
}

export default SplashScreen

import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css"
import "./TopHeaderCellV2.css";
import ProgressBarView from '../../../../../components/progress_bar/ProgressBarView.jsx'
import { DivCenterHorizontalStyled, getBizColorNumber } from '../../../../../biz_styles/bizStyle';
import { getCustomer, getBiz, getLanguage } from '../../../../../shared/redux/GlueStoreData';
import { membershipName } from '../../../../../shared/static_helper/StaticHelper';
import { IconStyledSvg } from '../../../../../biz_styles/IconsStyles'
import { BoldColorLabelStyled, SpacerStyled } from '../../../../../biz_styles/ReusableStyles.jsx';
import BarcodeComponent from '../../../../../components/barcode_component/BarCodeComponent.jsx';
class TopHeaderCellV2 extends Component {

    constructor(props) {
        super(props)
        this.bizObject = getBiz();
        this.customerVar = getCustomer();
        this.showPrepaidCard = this.customerVar.prepaid_card;
        this.getLang = getLanguage();
        this.showProgresBar = Number(getCustomer().membership.next_membership_req_points) > 0;
    }

    getGreetText() {
        return this.props.greetText;
    }

    getPointsValueText() {
        return this.props.points;
    }

    getMemberValueText() {
        return membershipName();
    }

    getNextTierPoints() {
        return getCustomer().membership.points_to_next_level;
    }

    getNextTierName() {
        return getCustomer().membership.next_membership_name;
    }

    getCompletedProgressValue() {

        var totalPoints = parseInt(getCustomer().membership.total_points);
        var pointsToNextLevel = parseInt(getCustomer().membership.points_to_next_level);
        var maxProgress = pointsToNextLevel + totalPoints;

        var percent = totalPoints / maxProgress * 100;

        if (percent == 0) {
            percent = 3
        }

        return percent;
    }

    getPointsToNextTierString() {

        var string = this.getLang.points_to_tier;
        string = string.replace("#amount#", this.getNextTierPoints());
        string = string.replace("#tier_name#", this.getNextTierName());

        return string;
    }

    render() {
        return (

            <div className='top-header-container' style={{ background: 'linear-gradient(' + getBizColorNumber(4) + ', rgba(0, 0, 0, 0))' }}>

                <div className='top-header-container-v2'>

                    <SpacerStyled height={'20'} />

                    <div className='bubbles-container'>

                        <div className='member_bubble_container' style={{ backgroundColor: getBizColorNumber(4) }}>
                            <div className="row">
                                <div style={{ position: 'relative', marginLeft: 'auto', marginRight: 'auto', marginTop: 16, marginBottom: 4 }}>
                                    <IconStyledSvg name="TrophyDuotune.svg" cssName='fill-color2' size={34} />
                                </div>

                                <span className='member_bubble_subtitle' style={{ color: getBizColorNumber(2) }}>{this.getMemberValueText()}</span>
                            </div>
                        </div>

                        <div className='points_bubble_container' style={{ backgroundColor: getBizColorNumber(4) }}>
                            <div className="row">
                                <span className='points_bubble_label' style={{ color: getBizColorNumber(2) }}>{this.getPointsValueText()}</span>
                                <span className='points_bubble_subtitle' style={{ color: getBizColorNumber(2) }}>{this.getLang.points.toUpperCase()}</span>
                            </div>
                        </div>

                    </div>


                    <div className="row">
                        <div className="points-balnace-container">
                            <span className='points-balnace-text bold-font' style={{ color: getBizColorNumber(2) }}>{this.getGreetText()}</span>
                        </div>
                    </div>

                    {this.showProgresBar ?

                        <div>

                            <ProgressBarView className='bottom-progress-bar' bgcolor={getBizColorNumber(3)} completed={this.getCompletedProgressValue()} />

                            <div className="row">
                                <span className='next-tier-text' style={{ color: getBizColorNumber(2), opacity: 0.5 }}>{this.getPointsToNextTierString()} </span>
                            </div>

                        </div> :

                        <div />}


                    {getCustomer().prepaid_card ?

                        <div>
                            <DivCenterHorizontalStyled>
                                <SpacerStyled height={'60'} />
                                <BoldColorLabelStyled style={{ color: getBizColorNumber(2), opacity: 0.5, width: 'auto', margin: 4 }} fontSize={20} color={getBizColorNumber(2)}>{this.getLang.cash_balance}:</BoldColorLabelStyled>
                                <BoldColorLabelStyled style={{ color: getBizColorNumber(2), opacity: 0.5, width: 'auto', margin: 4 }} fontSize={20} color={getBizColorNumber(2)}>{getCustomer().prepaid_card.balance + this.bizObject.ess_currency_symbol}</BoldColorLabelStyled>
                            </DivCenterHorizontalStyled>

                            <DivCenterHorizontalStyled style={{ margin: -30 }}>
                                <BarcodeComponent value={this.customerVar.prepaid_card.barcode} height={70} />
                            </DivCenterHorizontalStyled>


                        </div>
                        :

                        <div />

                    }

                </div>

            </div>


        );
    }
}

export default TopHeaderCellV2;
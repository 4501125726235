import React, { useState, useEffect } from 'react'
import { getBizColorNumber } from '../../biz_styles/bizStyle'
import InnerTabs from './Tabs'
import './PointsConversionDrawer.css'
import { TitleStyled } from '../../biz_styles/PersonalPointsStyle'
import { DivCenterStyled } from '../../biz_styles/ReusableStyles'
import { getPersonalzone, getConversionInitialDataForCustomer, getPointsAmountAvailableForConversionForCustomer } from '../../api/ApiRequests'
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import { IconStyledSvg } from '../../biz_styles/IconsStyles'
import { getLanguage } from '../../shared/redux/GlueStoreData'


export default function PointsConversionDrawer({ drawerClosed }) {

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [pointsData, setPointsData] = useState("")
    const [calculatorData, setCalculatorData] = useState("")
    const getLang = getLanguage();


    useEffect(() => {
        (async () => {
            setPointsData(await getConversionInitialDataForCustomer())
            setCalculatorData(await getPointsAmountAvailableForConversionForCustomer())
            setIsLoadingData(false)
        })()
    }, []);


    return (
        <div
            className='points-conversion-holder'
            style={{ backgroundColor: getBizColorNumber(1) }}>

            <DivCenterStyled style={{ marginTop: 50 }}>
                <IconStyledSvg name="PointsConversion.svg" cssName='fill-color2' size={42} />
            </DivCenterStyled>

            <DivCenterStyled>
                <TitleStyled>{getLang.points_conversion_drawer_title}</TitleStyled>
            </DivCenterStyled>

            {isLoadingData
                ?
                <LoadingComponent isLoading={isLoadingData} />
                :
                <InnerTabs pointsData={pointsData} calculatorData={calculatorData} drawerClosed={drawerClosed} />}
        </div>
    )
}

import React, { Component } from 'react';
import { RowDivStyled } from '../../biz_styles/ReusableStyles';
import Slider from '@mui/material/Slider';
import { getBizColorNumber } from '../../biz_styles/bizStyle';

function SliderStyled(props) {
    return (
        <Slider
            value={props.value}
            min={props.minvalue}
            max={props.maxvalue}
            onChange={props.onChange}
            onChangeCommitted={props.onChangeCommitted}
            valueLabelDisplay={'auto'}
            step={1}
            disabled={props.disabled}
            sx={{
                width: 300,
                height: 8,
                color: getBizColorNumber(3),
                '& .MuiSlider-thumb': {
                    width: 28,
                    height: 28,
                    borderRadius: '4px',
                    backgroundColor: getBizColorNumber(3)
                },
                '& .MuiSlider-thumb:hover': {
                    '-webkit-box-shadow': 'none !important',
                    boxShadow: 'none'
                },
                '& .Mui-focusVisible': {
                    '-webkit-box-shadow': 'none !important',
                    boxShadow: 'none !important'
                },
                '& .MuiSlider-track': {
                    height: 8,
                    color: getBizColorNumber(3)
                },
                '& .MuiSlider-rail': {
                    height: 8,
                    color: getBizColorNumber(3)
                },
                '& .MuiSlider-valueLabelLabel': {
                    color: getBizColorNumber(3)
                },
                '& .MuiSlider-valueLabelLabel': {
                    color: getBizColorNumber(2),
                    backgroundColor: getBizColorNumber(4)
                },

                '& .MuiSlider-valueLabelOpen': {
                    backgroundColor: getBizColorNumber(4)
                },

                '& .MuiSlider-valueLabel': {
                    backgroundColor: getBizColorNumber(4)
                },
                '& .MuiSlider-valueLabel:before': {
                    backgroundColor: getBizColorNumber(4)
                },
                '&.Mui-disabled': {
                    opacity: 0.5, // Reduce opacity for disabled state
                    pointerEvents: 'none' // Prevent interaction
                }


            }}
        />
    );
}

export default SliderStyled;
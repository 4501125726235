import React, { useState } from "react";
import { getBizColorNumber, DivHorizontalStyled, PStyled, POpacityStyled, H5Styled } from "../../biz_styles/bizStyle";
import { getDateFormatWithDateFormat } from "../../shared/static_helper/StaticHelper";
// import './HistoryPointsScreen.css'
import { InfiniteScroller } from "../../components/Infinite_scroller/InfiniteScroller";
import { getLanguage } from "../../shared/redux/GlueStoreData";
import { getCustomerConversionHistory } from "../../api/ApiRequests";
import { useStoreProvider } from '../../shared/providers/StoreContext'

export default function PointsConversionHistory({ prevHistoryData, totalCash }) {

    const [historyData, setHistoryData] = useState(prevHistoryData);
    const [hasMore, setHasMore] = React.useState(true);
    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz

    async function callApi() {

        const dataFromApi = await getCustomerConversionHistory(historyData.length)
        setHistoryData(prevState => ([...prevState, ...dataFromApi]));

        if (dataFromApi.length < 10) {
            setHasMore(false)
        }

    }

    function currentBalanceLayout() {

        return <DivHorizontalStyled>

            <div>
                <PStyled>{getLang.total_converted_cash}:</PStyled>
            </div>

            <H5Styled style={{fontStyle: 'bold'}} className="margin-auto-end">
                {bizObject.ess_currency_symbol + totalCash}
            </H5Styled>

        </DivHorizontalStyled>
    }


    function nothingHereYetLayout() {

        return <div
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}>

            <PStyled>{getLang.nothing_here_yet}</PStyled>

        </div>
    }

    function showHistoryList() {

        return historyData
            .map(
                (historyItem) =>

                    <div key={historyItem.entry_id}>
                        {cellForHistoryItem(historyItem)}
                    </div>
            );
    }

    function cellForHistoryItem(historyItem) {

        return <div>

            <DivHorizontalStyled>

                <div>

                    <PStyled
                        style={{ margin: '0px' }}>
                        <span style={{ direction: 'ltr', unicodeBidi: 'plaintext' }}>{historyItem.amount}</span> {bizObject.ess_currency_symbol}
                    </PStyled>

                    <POpacityStyled
                        opacity={'66'}
                        style={{
                            fontSize: '13px',
                            margin: '0px'
                        }}>
                        {getLang.total_converted_points + ': ' + historyItem.points_amount}
                    </POpacityStyled>

                </div>

                <POpacityStyled
                    className='p-date-list-layout-history-item-history-points'
                    opacity={'66'}
                    style={{ fontSize: '13px' }}>
                    {getDateFormatWithDateFormat(historyItem.date, 'll')}
                </POpacityStyled>

            </DivHorizontalStyled>

            <hr style={{ backgroundColor: getBizColorNumber(2) + '66' }} />

        </div>
    }

    return (

        <div>
            <div>
                {currentBalanceLayout()}

                <hr style={{ width: '100%', backgroundColor: getBizColorNumber(2) + '66' }} />
            </div>

            <div >
                {historyData.length === 0 ?

                    nothingHereYetLayout() :

                    <InfiniteScroller
                        dataLength={historyData.length} //This is important field to render the next data
                        apiRequestMethod={() => callApi()} // This is the api function
                        hasMore={hasMore} // Indicator
                        children={showHistoryList} // This is the content component
                        scrollableTarget="scrollableDiv" /> // This is the parent id
                }
            </div>

        </div>



    )

}
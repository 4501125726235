import { Skeleton } from '@mui/material';
import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from '@mui/material';

export const StyledPhoneInput = styled(PhoneInput)`
&.react-tel-input .country-list .country .dial-code {
  color:${() => getBizColorNumber(2)} !important;
}
&.react-tel-input .country-list .country.highlight {
    background-color:${() => getBizColorNumber(3) + 20} !important;
}
&.react-tel-input .country-list .country:hover {
    background-color:${() => getBizColorNumber(3) + 20} !important;
}
&.react-tel-input .flag-dropdown.open .selected-flag {
    background:${() => getBizColorNumber(3) + 20} !important;
}
&.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background:${() => getBizColorNumber(3) + 20} !important;
}
`

export const ButtonStyled = styled.button`
    color: ${() => getBizColorNumber(1)};
    background-color: ${() => getBizColorNumber(5)};
    font-size: 15px;
    width: 200px;
    margin: 20px;
    display: block;
    padding: 0.25em 1em;
    border: 0px solid;
    border-radius: 10px;`

export const FloatingButtonStyled = styled(ButtonStyled)`
    position: fixed;
    width: auto;
    height: 40px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    bottom: 12%;`

export const DivCenterStyled = styled.div`
    text-align: center;`

export const DivCenter2Styled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;`

export const DivSideAlignStyled = styled.div`
    display: flex;
    align-items: right;
    justify-content: right;`

export const IconStyled = styled.svg`
    fill:${() => getBizColorNumber(3)};`

export const ScrollableDivStyled = styled.div`
    overflow: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar{display:none;}
    height: ${(props) => props.height};`

export const TextAreaStyled = styled.textarea`
    width: 100%;
    height: ${(props) => props.height};`

// Create a Title component that'll render an <h1> tag with some styles
export const TitleStyled = styled.h1`
    font-size: 1.5em;
    text-align: center;
    color:${() => getBizColorNumber(2)};`

export const MenuTitleStyled = styled.h1`
    font-size: 16px;
    text-align: center;
    color:${() => getBizColorNumber(2)};`

export const MenuTitleWithoutColorStyled = styled.h1`
    font-size: 16px;
    text-align: center;`

export const SubtitleStyled = styled(TitleStyled)`
    font-size: 12px;
`

export const ColorSubtitleStyled = styled(SubtitleStyled)`
    color: ${(props) => props.color};
`

export const HorizontalScrollDivStyled = styled.div`
    position: relative;
    padding-left: ${(props) => props.sidePadding}px;
    padding-right: ${(props) => props.sidePadding}px;
    width: 100%;
    height:${(props) => props.height}px;
    overflow-x: scroll;
    overflow-y: hidden;
    `

export const HorizontalCellDivStyled = styled.div`
    position: relative;
    height: 100%;
    width: ${(props) => props.cellWidth}px;`

export const SkeletonStyled = styled(Skeleton)`
    background-color: ${() => getBizColorNumber(4)};
    margin-left: ${(props) => typeof (props.leftMargin) !== 'undefined' ? props.leftMargin : 10}px;
    margin-top: 0;`

export const BottomContainerDivStyled = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0px;
    height:${(props) => props.height}px;
    background-color: #F2F2F2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);`

export const CartButtonStyled = styled.button`
    color: ${() => getBizColorNumber(3)};
    background-color: ${() => getBizColorNumber(4)};
    font-size: 15px;
    width: ${(props) => props.width};
    height: 40px;
    margin: 13px 0px 0px 13px;
    display: inline;
    padding: 0.25em 1em;
    border: 0px solid;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;`

export const CartInvertedButtonStyled = styled(CartButtonStyled)`
    color: ${() => getBizColorNumber(2)};
    opacity: ${(props) => props.opacity};
    background-color: ${() => getBizColorNumber(3)};`

export const SpacerStyled = styled.div`
    height: ${(props) => props.height}px;`

export const ColorSpacerStyled = styled.div`
    height: ${(props) => props.height}px;
    background-color: ${(props) => props.color};`


export const KeyPStyled = styled.span`
    color: ${(props) => getBizColorNumber(2) + props.opacity};
    font-size: ${(props) => props.fontSize};
    position: relative;`

export const GenericTitleStyled = styled.span`
    color: ${(props) => props.color};
    font-size: ${(props) => props.fontSize}px;
    position: relative;`

export const GenericBoldTitleStyled = styled(GenericTitleStyled)`
    class-name: bold-font;
`

export const ValuePStyled = styled.span`
    position: relative;
    width: ${(props) => props.width};
    height: ${(props) => props.fontSize}px;
    overflow: hidden;
    color: ${() => getBizColorNumber(2)};
    background-color: transparent;
    font-size: ${(props) => props.fontSize};
    float: right;
    direction: ltr;`

export const RowDivStyled = styled.div`
    position: relative;
    background-color: transparent;
    margin-right:${(props) => props.marginRight}px;
    margin-left:${(props) => props.marginLeft}px;
    padding: ${(props) => props.padding}px;
    width: 100%;`

export const CellCardStyled = styled.div`
    position: relative;
    background-color: white;
    margin: 20px 5px 20px 5px; 
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: ${(props) => props.height}px;
    overflow: hidden;
    width: 98%;`

export const PersonalCellHolder = styled.div`
    position: relative;
    height: ${(props) => props.height}px;
    width: 100%;
    background-color: transparent;`

export const PersonalContainerView = styled.div`
    position: relative;
    height: 100%;
    margin: 20px;
    background-color: ${() => getBizColorNumber(4)};
    border-radius: 10px;
    border-width: 1px;
    border-color: rgba(117, 91, 90,0.5);
    border-style: ridge;`

export const DivDashedStyled = styled.div`
    border-style: dashed;
    border-radius: 8px;
    border-color: ${() => getBizColorNumber(2)};
    padding: 10px;
    background-color: ${(props) => props.backgroundColor}`

export const DivBackgroundStyled = styled.div`
    min-height: 100vh;
    background-color: ${() => getBizColorNumber(1)};`

export const BackButtonStyled = styled.button`
    position: static;
    color: ${() => getBizColorNumber(3)};
    background-color: ${() => getBizColorNumber(4)};
    width: 40px;
    height: 40px;
    margin: 13px 0px 0px 13px;
    border-radius: 20px;
    display: inline;
    padding: 0.25em 1em;
    border: 0px solid;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    `
export const SpacerHorizontalStyled = styled.div`
    width: ${(props) => props.width}px;`

export const LogoRoundedContainerStyled = styled.img`
    margin-top: 50px;
    height: 100px;
    border-style: solid;
    border-width: 2px;
    border-color: ${() => getBizColorNumber(2)};
    border-radius: 25px`

export const ScreenContainerDivStyled = styled.div`
    position: relative; 
    height: 100%;
    background-color: ${(props) => props.color};
    `

export const DivXYCenterStyled = styled.div`
    position: relative;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);`

export const DivXCenterStyled = styled.div`
    position: relative;
    text-align: center; 
`

export const SideMarginDivStyled = styled.div`
    position: relative; 
    height: ${(props) => props.height ? props.height : 'calc(100% - 80px)'};
    margin-right:${(props) => props.sidemargin ? props.sidemargin : 0};
    margin-left:${(props) => props.sidemargin ? props.sidemargin : 0};
    background-color:${(props) => props.color};
    padding-right:${(props) => props.sidepadding ? props.sidepadding : 0};
    padding-left:${(props) => props.sidepadding ? props.sidepadding : 0};
    max-width: ${(props) => props.maxwidth ? props.maxwidth : 'initial'};
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    `

export const CardDivStyled = styled.div`
    position: relative;
    width: ${(props) => props.width ? props.width : ''};
    height: ${(props) => props.height ? props.height + 'px' : 'auto'};
    margin-right:${(props) => props.sidemargin};
    margin-left:${(props) => props.sidemargin};
    background-color:${(props) => props.color};
    border-radius: 10px;
    border: 1px solid ${(props) => props.strokecolor};
    `

export const PrimeTitleStyled = styled.div`
    color:${(props) => props.color};
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    `

export const PrimeTitleInputStyled = styled.input`
  color: ${(props) => props.color};
  width: 100%;
  text-align: center;
  font-size: ${(props) => props.fontSize}px;
  font-weight: bold;
  position: relative;
  border: none;
  background: transparent;
  outline: none;

  &:focus {
    outline: none; /* Remove the default browser outline on focus */
  }
`;

export const PrimeLabelStyled = styled(PrimeTitleStyled)`
    color:${(props) => props.color};
    line-height: 30px;
    font-weight: normal;
    font-size: 16px;`

export const ColorLabelStyled = styled(PrimeLabelStyled)`
    color:${(props) => props.color};
    font-size: ${(props) => props.fontSize}px;
    text-align: ${(props) => props.align};
    `

export const BoldColorLabelStyled = styled(ColorLabelStyled)`
    font-weight: bold;
    `
export const SecondLabelStyled = styled(PrimeLabelStyled)`
    color:${(props) => props.color}    
    `

export const FixedBottomDivStyled = styled.div`
    position: relative;
    bottom: ${(props) => props.bottom ? props.bottom : 0}px;
    left: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: ${(props) => props.height}px;
    `

export const BigButtonStyled = styled(Button)(({ theme }) => ({
    "&&&": {
        width: '100%',
        maxWidth: '360px',
        borderRadius: '50px !important',
        height: '56px',
        color: getBizColorNumber(1),
        backgroundColor: getBizColorNumber(5),
        fontSize: '18px !important',
        fontWeight: 'bold !important',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    "&&.Mui-disabled": {
        backgroundColor: '#C8CCCA',
        color: 'white'
    }
}));
